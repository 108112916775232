<!-- 带查询条件的树形结构 -->
<template>
  <div class="h-full">
    <el-input v-model="keyWords" palceholder="请输入关键字查询" clearable @change="handleInputChange">
      <el-button slot="append" icon="el-icon-search" @click="handleInputChange"></el-button>
    </el-input>
    <el-tree
      ref="treeRef"
      :loading="loading"
      :node-key="nodeKey"
      :data="data"
      :default-expand-all="false"
      :expand-on-click-node="expandOnClickNode"
      :default-expanded-keys="defaultExpandedKeys"
      :show-checkbox="showCheckbox"
      :props="defaultProps"
      :highlight-current="highlightCurrent"
      :current-node-key="currentNodeKey"
      :accordion="accordion"
      :draggable="draggable"
      :allow-drop="allowDrop"
      class="tree-data mt-[10px]"
      style="height: calc(100% - 44px); overflow: auto;"
      @node-click="handleNodeClick"
      @node-drag-start="handleDragStart"
      @node-drag-enter="handleDragEnter"
      @node-drag-leave="handleDragLeave"
      @node-drag-over="handleDragOver"
      @node-drag-end="handleDragEnd"
      @node-drop="handleDrop"
    />
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    nodeKey: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'name',
          id: 'code'
        }
      }
    },
    highlightCurrent: {
      type: Boolean,
      default: true
    },
    currentNodeKey: {
      type: [Number, String],
      default: null
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    accordion: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    expandOnClickNode: {
      type: Boolean,
      default: true
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => [0]
    }
  },
  data() {
    return {
      keyWords: ''
    }
  },
  computed: {
    firstLevelKeys() {
      return this.data.map(item => item[this.nodeKey]);
    }
  },
  watch: {
    currentNodeKey: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          this.$refs.treeRef.setCurrentKey(val)
        })
      }
    }
  },
  mounted() {
    // 设置默认展开第一层
    this.$nextTick(() => {
      if (this.$refs.treeRef) {
        this.$refs.treeRef.setCurrentKey(this.firstLevelKeys);
      }
    });
  },
  methods: {
    handleInputChange() {
      this.$emit('onSearch', this.keyWords)
    },
    handleNodeClick(data, node, ev) {
      this.$emit('nodeClick', data, node, ev)
    },
    handleDragStart(node, ev) {
      this.$emit('nodeDragStart', node, ev)
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      this.$emit('nodeDragEnter ', draggingNode, dropNode, ev);
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      this.$emit('nodeDragLeave', draggingNode, dropNode, ev);
    },
    handleDragOver(draggingNode, dropNode, ev) {
      this.$emit('nodeDragOver', draggingNode, dropNode, ev);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      this.$emit('nodeDragEnd', draggingNode, dropNode, dropType, ev);
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      this.$emit('nodeDrop', draggingNode, dropNode, dropType, ev);
    },
    allowDrop(draggingNode, dropNode, type) {
      return dropNode.data.id !== 0 && dropNode.data.id !== null && dropNode.data.id !== ''
    },
    allowDrag(draggingNode) {
      this.$emit('allowDrag', draggingNode);
    }
  }
}

</script>
