var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "el-input",
        {
          attrs: { palceholder: "请输入关键字查询", clearable: "" },
          on: { change: _vm.handleInputChange },
          model: {
            value: _vm.keyWords,
            callback: function ($$v) {
              _vm.keyWords = $$v
            },
            expression: "keyWords",
          },
        },
        [
          _c("el-button", {
            attrs: { slot: "append", icon: "el-icon-search" },
            on: { click: _vm.handleInputChange },
            slot: "append",
          }),
        ],
        1
      ),
      _c("el-tree", {
        ref: "treeRef",
        staticClass: "tree-data mt-[10px]",
        staticStyle: { height: "calc(100% - 44px)", overflow: "auto" },
        attrs: {
          loading: _vm.loading,
          "node-key": _vm.nodeKey,
          data: _vm.data,
          "default-expand-all": false,
          "expand-on-click-node": _vm.expandOnClickNode,
          "default-expanded-keys": _vm.defaultExpandedKeys,
          "show-checkbox": _vm.showCheckbox,
          props: _vm.defaultProps,
          "highlight-current": _vm.highlightCurrent,
          "current-node-key": _vm.currentNodeKey,
          accordion: _vm.accordion,
          draggable: _vm.draggable,
          "allow-drop": _vm.allowDrop,
        },
        on: {
          "node-click": _vm.handleNodeClick,
          "node-drag-start": _vm.handleDragStart,
          "node-drag-enter": _vm.handleDragEnter,
          "node-drag-leave": _vm.handleDragLeave,
          "node-drag-over": _vm.handleDragOver,
          "node-drag-end": _vm.handleDragEnd,
          "node-drop": _vm.handleDrop,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }